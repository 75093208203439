import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { Route, useHistory, Switch } from 'react-router-dom';
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Api from '../Service/network';
import moment from 'moment';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import ArchiveIcon from '@mui/icons-material/Archive';
const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

export default function Listing({check,count}) {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("700px");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(false);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const [listing, setListing] = useState([]);

  const [listingReload, setListingReload] = useState();
  const [budgetListing, setBudgetListing] = useState([]);
  const[budgetLoading,setBudgetLoading] = useState(false);
  const[loading,setLoading] = useState(false);

  const [eyeListing, setEyeListing] = useState([]);
  const[eyeLoading,setEyeLoading] = useState(false);

  const[campaignStatus, setCampaignStatus] = useState('green');
  const history = useHistory();

    useEffect(()=>{


        setLoading(true);

        const getUserEmail = JSON.parse(localStorage.getItem('user_details'));

        const accountsEndpoint = process.env.REACT_APP_BASE_URL + "subCampaign/email/" + getUserEmail.email;
       
        Api(accountsEndpoint,"GET")
        .then(result => {
            
         // console.log(result.data,"get me data");
         
          setListing(result.data);
          setLoading(false);

         }).catch((err)=>console.log(err))
    
      

    },[listingReload]);


    useEffect(()=>{

       

      setBudgetLoading(true);

      const getUserEmail = JSON.parse(localStorage.getItem('user_details'));

      const accountsEndpoint = process.env.REACT_APP_BASE_URL + "budget/" + getUserEmail.email;
     
      Api(accountsEndpoint,"GET")
      .then(result => {
  
        
   
      //  console.log(result.data,"get budget listing");

          setBudgetListing(result.data);
          setBudgetLoading(false);



       }).catch((err)=>console.log(err))
  
    

  },[]);


  useEffect(()=>{

       

    setEyeLoading(true);

    const getUserEmail = JSON.parse(localStorage.getItem('user_details'));

    const accountsEndpoint = process.env.REACT_APP_BASE_URL + "subCampaign/actions/email/" + getUserEmail.email;
   
    Api(accountsEndpoint,"GET")
    .then(result => {

        setEyeListing(result.data);
        setEyeLoading(false);

     }).catch((err)=>console.log(err))

},[]);


  const deleteCampaign = (id) =>{

    //console.log(e);
    let isExecuted = window.confirm("Are you sure you want to delete this Sub Campaign?");
    if(isExecuted){

    const endpoint = process.env.REACT_APP_BASE_URL+"subCampaign/delete/"+id;
    // setClientLoading(true);
     Api(endpoint,"PUT")
    .then(result => {
        
      setListingReload([])
      //setC([]);
      
     }).catch((err)=>console.log(err))
    }

  }



  const archiveCampaign = (id) =>{

    //console.log(e);
    let isExecuted = window.confirm("Are you sure you want to Archive this Sub Campaign?");
    if(isExecuted){

    const endpoint = process.env.REACT_APP_BASE_URL+"subCampaign/archive/"+id;
   // setClientLoading(true);
     Api(endpoint,"PUT")
    .then(result => {
        
      setListingReload([])
        //setC([]);
      
     }).catch((err)=>console.log(err))
    }

  }


  const columns = [
 
    { name: "Campaign Name", options: {
        filter:false,
        setCellProps: () => ({ style: { width:250  }}), 
        setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white',  }}),
        customBodyRenderLite: (dataIndex) => {
          return (
            <a onClick={(e) => {

              check(data[dataIndex][7])

            }}>
           
            <span style={{cursor:'pointer'}} > {data[dataIndex][0]}</span> 
            </a>
          );
        }
        
        }, 
        
    },

    { name: "Budget Left", options: {
      
      setCellProps: () => ({ style: { width:140  }}), 
        setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
       
       
        }, 
    },

    { name: "Total Budget", options: {
      setCellProps: () => ({ style: { width:160  }}), 
        setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
       
        }, 
    },
    { name: "Advertiser", options: {
       setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
       setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',width:'200px'}}),
      
       
      
       }, 
   },
   { name: "Master Campaign", options: {
   
     setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white', width:'200px'}}),
    
     
    
     }, 
 },

    { name: "Start Date", options: {
       
       setCellProps: () => ({ style: { width:160  }}), 
        setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white' ,}}),
        customBodyRender: value => {
            return <span style={{padding:5}}><i className="las la-calendar"></i> {value}</span>
          }
        }, 
    },
    { name: "End Date", options: {
         setCellProps: () => ({ style: { width:160  }}), 
    
        setCellHeaderProps: () => ({ style:  { backgroundColor: '#4169e1', color:'white',  }}),
        
       customBodyRender: value => {
            return <span style={{padding:5}}><i className="las la-calendar"></i> { value }</span>
          }
        
        }, 
    },     
    {name: "campaign id",  options: {display: false, viewColumns: false, filter: false}},
    //{name: "client",  options: {display: false, viewColumns: false, filter: false}},
    {name: "campaign owner",  options: {display: false, viewColumns: false, filter: true}},
    {name: "platform",  options: {display: false, viewColumns: false, filter: false}},
    {name: "goal",  options: {display: false, viewColumns: false, filter: false}},
    {name: "team leader",  options: {display: false, viewColumns: false, filter: false}},
    {name: "performance manager",  options: {display: false, viewColumns: false, filter: false}},
    {name: "campaign backup",  options: {display: false, viewColumns: false, filter: true}},
    {name: "campaign type",  options: {display: false, viewColumns: false, filter: false}},
    {name: "optimization kpi",  options: {display: false, viewColumns: false, filter: false}},
    {name: "secondary kpi",  options: {display: false, viewColumns: false, filter: false}},
 //   {name: "master campaign",  options: {display: false, viewColumns: false, filter: false}},
    {name: "master campaign id",  options: {display: false, viewColumns: false, filter: false}},
    {name: "campaign",  options: {display: false, viewColumns: false, filter: false}},
    {name: "estimated goal",  options: {display: false, viewColumns: false, filter: false}},
    {name: "start date",  options: {display: false, viewColumns: false, filter: false}},
    {name: "end date",  options: {display: false, viewColumns: false, filter: false}},
    {name: "modes",  options: {display: false, viewColumns: false, filter: false}},
    {name: "periodsInfo",  options: {display: false, viewColumns: false, filter: false}},
    {name: "notes",  options: {display: false, viewColumns: false, filter: false}}, //256row
    {name: "periods info",  options: {display: false, viewColumns: false, filter: false}}, //256row
    {name: "subcampaign",  options: {display: false, viewColumns: false, filter: false}},
    {name: "totalbudgetwithousign",  options: {display: false, viewColumns: false, filter: false}},
    {name: "",  options: {  filter: false, setCellHeaderProps: () => ({ style:  { backgroundColor: '#4169e1', color:'white',  }})}},
    {name: "",  options: {  filter: false, setCellHeaderProps: () => ({ style:  { backgroundColor: '#4169e1', color:'white',  }})}},

  {
      name: "",
      options: {
       
        setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white',  }}),
        filter: false,
        viewColumns:false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <a onClick={(e) => {
              e.stopPropagation()
              editCampaign(data[dataIndex][7]);
            // console.log(data[dataIndex][3],"afds");
            }}>
            <span className="las la-edit" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
            </a>
          );
        }
      }
    },

    {
      name: "",
      options: {
        
        setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white',  }}),
        filter: false,
        viewColumns:false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <a onClick={(e) => {
              e.stopPropagation()
              archiveCampaign(data[dataIndex][7]);
            // console.log(data[dataIndex][3],"afds");
            }}>
            <span className="las la-archive" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
            </a>
          );
        }
      }
    }, 
  {
      name: "",
      options: {
      
        setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white',  }}),
        filter: false,
        viewColumns:false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <a onClick={(e) => {
              e.stopPropagation()
              deleteCampaign(data[dataIndex][7]);
            // console.log(data[dataIndex][3],"afds");
            }}>
            <span className="las la-trash" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
            </a>
          );
        }
      }
    },
    


    ];

     
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "multiselect",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    rowsPerPage:50,
    sortOrder: {
        name: 'name',
        direction: 'desc'
    },
    responsive: "standard",
   // onRowClick: rowData => {check(rowData)},//console.log(rowData,"check data") },
    


   //console.log(rowData,"check data") }
   /*
   onCellClick: (rowData, rowIndex) => {

    if(rowIndex.colIndex === 0){
      
     console.log(rowData, "---", rowIndex)

    }
  }*/
  
     
  };

 
  
  const data = listing.map((a,b)=> {

 
   

    const cname = a.sub_campaign_name.length < 35 ? a.sub_campaign_name :  a.sub_campaign_name.substring(0,40) + " " + a.sub_campaign_name.substring(40,80) + " " + a.sub_campaign_name.substring(80,150) ;


  return([
    cname,
   // campaignName,
    budgetLoading ?  <LinearProgress style={{width:'20%', marginLeft:'20px'}}/>:isNaN(parseFloat(a.total_budget - budgetListing[b].budget).toFixed(2)) ? 0 : parseFloat((a.total_budget - budgetListing[b].budget)).toLocaleString().replace(/,/g," ",).replace('.',',')  + " €" ,
    a.total_budget.toLocaleString().replace(/,/g," ",).replace('.',',') +" €",
    a.Client,
    a.master_campaign_name.substring(0,23) + " ....",
    moment.utc(new Date(a.start_date)).local().format("YYYY-MM-DD"), 
    moment.utc(new Date(a.end_date)).local().format("YYYY-MM-DD"), 
    a._id, 
    a.campaign_owner,
    a.platform,
    a.goal,
    a.team_leader,
    a.performance_manager,
    a.campaign_backup,
    a.campaign_type,
    a.opt_kpi,
    a.secondary_kpi,
    a.master_campaign_id,
    a.campaigns,
    a.estimated_goal,
    a.start_date,
    a.end_date,
    a.mode,
    a.mode == "periods" ? a.periods_info : '',
    a.note,
    a.periods_info, 
    a.sub_campaign_name,   //to send full subcampaign stirng
    a.total_budget,
    eyeLoading ?  <LinearProgress style={{width:'20%', marginLeft:'20px'}}/>  : eyeListing[b].action ? <span className="las la-eye" style={{color:'#5a7ff1',marginLeft:5, fontSize:20}} ></span>  : "",
    a.campaign_status == "Orange" ? <span className="las la-exclamation-circle" style={{cursor:'pointer', marginLeft:5, fontSize:20, color:'orange'}} ></span> : a.campaign_status == "Red" ? <span className="las la-exclamation-circle" style={{cursor:'pointer', marginLeft:5, fontSize:20, color:'red'}} ></span> : ''
    
  ])});



  const editCampaign = (id) =>{

    history.push({
      pathname: '/addCampaign',
      search: '?cid='+id,
    }) 

  }

  
  return (
    <>


<div className="card"  >
    {loading ? 
    <div>

    <LinearProgress style={{marginBottom:5}} />
    <div style={{padding:50,textAlign:'center'}}>Loading campaign ....... Please Wait !!!</div>
        
        
    </div> :
    <div style={{padding:20, }}>
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={createTheme()}>
       
        <MUIDataTable
          // title={"Campaign Information"}   
          data={data}
          columns={columns}
          options={options}
        
         
         
        />
      </ThemeProvider>
    </CacheProvider>
    </div>
    }



<div style={{width:'100%',height:800}}>
<iframe style={{background: "#F1F5F4", border: "none", borderRadius:"2px", boxShadow:"0 2px 10px 0 rgba(70, 76, 79, .2)",width: "100%",height: "600%"}}  src="https://charts.mongodb.com/charts-project-0-jofbt/embed/dashboards?id=9c8ed56a-0e08-4078-bd29-de70bbab54e8&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed"></iframe>
</div>



    </div>



    </>
  );
}